import React, { useContext, useEffect, useState } from "react";

import {
  sendPasswordResetEmail,
  signInWithCustomToken,
  User,
} from "firebase/auth";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import { auth } from "shared/firebaseConfig/firebase";
import { createShopToken } from "shared/generated/clients/auth.client";

/* eslint-disable */
const AuthContext = React.createContext<{
  currentUser: User | null;
  shopId: number | null;
  login: (username: string, password: string) => Promise<void>;
  loginWithToken: (token: string) => Promise<void>;
  resetPassword: (username: string) => Promise<void>;
  logout: () => Promise<void>;
}>({
  currentUser: {} as User,
  shopId: null,
  login: async (username: string, password: string) => {},
  resetPassword: async (username: string) => {},
  loginWithToken: async (token: string) => {},
  logout: async () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({
  children,
  disableAuth,
}: {
  children: React.ReactNode;
  disableAuth: boolean;
}) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [shopId, setShopId] = useState<number | null>(null);
  const ldClient = useLDClient();
  const router = useRouter();
  useEffect(() => {
    if (currentUser && shopId) {
      console.log("authprovider ldclient", ldClient);
      ldClient?.identify({
        key: currentUser.uid,
        email: currentUser.email ?? undefined,
        name: currentUser.displayName ?? undefined,
        custom: {
          shopId,
        },
      });
    }
  }, [currentUser, shopId]);

  async function login(email: string, password: string) {
    const response = await createShopToken({
      body: {
        email,
        password,
      },
    });

    if (response.error) {
      throw new Error(response.error.message ?? "Could not sign in");
    }

    await loginWithToken(response.data.token);
  }

  async function loginWithToken(token: string) {
    const { redirectTo } = router.query;
    const credential = await signInWithCustomToken(auth, token);
    setCurrentUser(credential.user);
    const cred = await credential.user.getIdTokenResult();
    const claims: any = cred.claims["https://hasura.io/jwt/claims"];
    setShopId(claims["x-hasura-shop-id"] as any);
    router.push(redirectTo?.toString() ?? "/");
  }

  function logout() {
    return auth.signOut();
  }

  async function resetPassword(email: string) {
    await sendPasswordResetEmail(auth, email);
  }

  const redirectToLogin = () => {
    router.push(`/auth/signin?redirectTo=${router.asPath}`);
  };

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      user?.getIdToken().then((token) => {
        window.localStorage.setItem("token", token);
      });
      setCurrentUser(user);
      user?.getIdTokenResult().then((token) => {
        const claims: any = token.claims["https://hasura.io/jwt/claims"];
        setShopId(claims["x-hasura-shop-id"] as any);
      });

      if (!disableAuth && !user) {
        redirectToLogin();
      }
    });
  }, [disableAuth, router]);

  const value = {
    currentUser,
    shopId,
    login,
    resetPassword,
    logout,
    loginWithToken,
  };
  /* eslint-enabled */

  return (
    <AuthContext.Provider value={value}>
      {disableAuth || currentUser ? children : null}
    </AuthContext.Provider>
  );
}
