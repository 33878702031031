import { createContext, useContext, useEffect, useState } from "react";

import { RequestCountOutput } from "../../../../backend/common/request.types";
import { convertStringToRequestState } from "../../../../backend/common/states";
// eslint-disable-next-line no-restricted-imports
import { useAuth } from "../../../apps/shops/providers/AuthProvider";
import { getUnreadRequests } from "../services/request.service";

interface UnreadRequestsContextType {
  totalUnreadRequests: number;
  unreadRequestsData: RequestCountOutput[];
  setRequestIds: (requestIds: number[]) => void;
  getUnreadMessagesCount: (requestId: number) => number;
  isUnopenedRequest: (requestId: number) => boolean;
}

const initialContext: UnreadRequestsContextType = {
  totalUnreadRequests: 0,
  unreadRequestsData: [],
  setRequestIds: (requestIds: number[]) => {
    console.info("setRequestIds not implemented: ", requestIds);
  },
  getUnreadMessagesCount: (requestId: number) => {
    console.info("getUnreadMessages not implemented: ", requestId);
    return 0;
  },
  isUnopenedRequest: (requestId: number) => {
    console.info("isUnopenedRequest not implemented: ", requestId);
    return false;
  },
};
export const UnreadRequestsContext =
  createContext<UnreadRequestsContextType>(initialContext);

export const useUnreadRequests = () => {
  const {
    totalUnreadRequests,
    unreadRequestsData,
    setRequestIds,
    getUnreadMessagesCount,
    isUnopenedRequest,
  } = useContext(UnreadRequestsContext);
  return {
    totalUnreadRequests,
    unreadRequestsData,
    setRequestIds,
    getUnreadMessagesCount,
    isUnopenedRequest,
  };
};

export function UnreadRequestsProvider({
  children,
  initialRequestIds = [],
}: {
  children: React.ReactNode;
  initialRequestIds?: number[];
}) {
  const { currentUser } = useAuth();
  const [unreadRequestsData, setUnreadRequestsData] = useState<
    RequestCountOutput[]
  >([]);
  const [requestIds, setRequestIds] = useState<number[]>(initialRequestIds);
  const [totalUnreadRequests, setTotalUnreadRequests] = useState(0);

  function getUnreadMessagesCount(requestId: number) {
    // find the request with the same id as the current request
    const unreadRequest = unreadRequestsData.find(
      (unreadRequest) => unreadRequest.id === requestId
    );
    return unreadRequest?.unreadMessagesCount ?? 0;
  }

  function isUnopenedRequest(requestId: number) {
    // find the request with the same id as the current request
    const unreadRequest = unreadRequestsData.find(
      (unreadRequest) => unreadRequest.id === requestId
    );
    return !!unreadRequest;
  }
  useEffect(() => {
    if (!requestIds.length) {
      setUnreadRequestsData([]);
      return;
    }
    let timeoutId: NodeJS.Timeout;

    const fetchUnreadRequests = async () => {
      if (!currentUser || !requestIds.length) {
        return;
      }

      let res;
      try {
        res = await getUnreadRequests(requestIds);
      } catch (e) {
        console.warn(`Error fetching unread requests: ${e}`);
        return;
      }

      if (res.error) {
        console.warn(`Error fetching unread requests: ${res.error.message}`);
      } else {
        const requestData = res.data.unreadRequests.flatMap((item) => {
          const convertedStatus = convertStringToRequestState(item.status);

          if (convertedStatus === null) {
            console.warn(`Invalid status value: ${item.status}`);
            return [];
          }

          return {
            ...item,
            status: convertedStatus,
          };
        });
        setUnreadRequestsData(requestData);
        setTotalUnreadRequests(res.data.totalUnreadRequestsCount);
      }
      // Reschedule the next fetch
      timeoutId = setTimeout(fetchUnreadRequests, 30000);
    };

    // Start the fetch operation
    fetchUnreadRequests();

    return () => {
      // Clear the timeout if the component unmounts
      clearTimeout(timeoutId);
    };
  }, [currentUser, requestIds]);

  return (
    <UnreadRequestsContext.Provider
      value={{
        totalUnreadRequests,
        unreadRequestsData,
        setRequestIds,
        getUnreadMessagesCount,
        isUnopenedRequest,
      }}
    >
      {children}
    </UnreadRequestsContext.Provider>
  );
}
